/*============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Ghalia Adzana
==============================================================================================*/
@import "sass-lib";

body {  
    color: #333;
    font-size: 14px;
    font-family: "Raleway"; 
    position: relative;
    overflow-x: hidden;
    -moz-text-size-adjust: 100%;
     -ms-text-size-adjust: 100%;
         text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
figure{
    margin: 0; padding: 0;
    img{vertical-align: middle;}
}
img{ border:0; max-width:100%; height:auto; }
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-size: 12px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button  {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
h1 { font-size: 32px; }
h2 { font-size: 28px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; } 
h5 { font-size: 16px; } 
h6 { font-size: 14px; }

/* structure 
----------------------------------------------------------------------------------------------*/
.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.flex-list {
    display: -webkit-box; 
    display: -ms-flexbox; 
    display: -webkit-flex;
    display: flex; 
    flex-flow: row wrap;
}
.anim{ position: absolute; left: 0; top: 0; bottom: 0; right: 0; margin: auto; height: 1px;  }
section { position: relative; }
.wrapper {
    width: 1170px;margin: 0 auto;
    &.v2{ width: 900px; }
}
.error{
    font-size: 14px;color: #FF2323; display: block; margin: 10px 0 5px;
}
.btn{
    padding-left: 15px; @include boxSizing(border-box);
    &.export{
        position: relative; padding: 0px 20px 0 55px;  @include boxSizing(border-box);
        line-height: 45px; @include borderRadius(25px); color: #093C81;  background: rgba(9,60,129,0.05); 
        overflow:hidden; margin: 0px 20px 0 0;
        &:before{ 
            content:''; position: absolute; left: 12%; top: 0; bottom: 0; margin: auto 0; 
            background: url(../images/material/ic-export.png)no-repeat; width: 18px; height: 18px;
        }
        &:after{
           content: ''; position: absolute;  background: rgba(9,60,129,0.1);  position: absolute; z-index: -1;
            top: 0; left: 0; right: 0; width: 100%; height: 0; 
            @include transition(.2s all $ease-out);
        }   
        &:hover{
            &:after{
                height:100%;
            }
        }
    }
    &.login{
        position: relative; padding: 0px 0px 0px 45px; line-height: 45px; color: #707070; @include boxSizing(border-box);
        @include transition(.2s all $ease-out); width: 105px; margin: 0px 15px;display: none;
        &:before{
            content:''; position: absolute; left: 12%; top: 0; bottom: 0; margin: auto 0;
            background: url(../images/material/ic-login.png)no-repeat center; width: 20px; height: 20px;
            @include transition(.2s all $ease-out); opacity: 0.7;
        }
        
        &:hover{
            color: #111; font-weight: bold;
            &:before{
                opacity:1;
            }
        }
    }
    &.gradient{
        padding: 0 60px 0 20px; line-height: 45px; color: #fff; @include borderRadius(25px);
        background: #82b93b; font-family: $f-m; font-size: 16px; display: inline-block; position: relative;
        overflow: hidden; margin-top: 25px;
        background: -webkit-gradient(left top, right bottom, color-stop(0%, #82b93b), color-stop(100%, #088439));
        background: linear-gradient(135deg, #82b93b 0%, #088439 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#82b93b', endColorstr='#088439', GradientType=1 );
         @include transition(.2s all $ease-out);
       span{
            background: url(../images/material/eclipse.png)no-repeat; width: 45px; height: 100%;
            background-size: cover;
            position: relative; display: inline-block; position: absolute;right: 0;top: 0; bottom: 0;
            @include transition(.2s all $ease-out);
            &:before{ 
                content:''; position: absolute; left: 0; top: 0; bottom: 0; margin: auto 0;  
                background: url(../images/material/ic-arr-wht.png)no-repeat center; width: 27px; height: 14px;
                @include transition(.2s all $ease-out);
            }
       }
       &:hover{
            background: rgba(130,185,59,1);
            background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(130,185,59,1)), color-stop(100%, rgba(8,132,57,1)));
            background: linear-gradient(45deg, rgba(130,185,59,1) 0%, rgba(8,132,57,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#82b93b', endColorstr='#088439', GradientType=1 );
            @include transition(.2s all $ease-out);
            span{
                @include transform(scale(1.1));
                &:before{
                   left:5px;
                }
            }
       }
    }
    &.rounded{
        padding: 0 40px; line-height: 60px;background: #82B93B; font-size: 16px; font-family: $f-m; letter-spacing: 1px; text-transform: uppercase;
        display: inline-block; @include borderRadius(35px); color: #fff; @include boxShadow(0 2px 20px -10px #000);
        @include transition(.2s all $ease-out); position: relative;
        &:hover{
            background:#8DBF4C; @include boxShadow(0px 10px 30px -15px #000);
        }
        &:active{ top: 2px; }
    }
    &.viewall-arr{
        color: #82B93A; font-size: 15px; font-weight: 500; padding:0 60px 0px 0; position: relative;
        line-height: 45px; display: inline-block;
        span{
            background: url(../images/material/eclipse.png)no-repeat; width: 45px; height: 100%;
            background-size: cover;
            position: relative; display: inline-block; position: absolute;right: 0;top: 0; bottom: 0;
            @include transition(.2s all $ease-out);
            &:before{ 
                content:''; position: absolute; left: 0; top: 0; bottom: 0; margin: auto 0;  
                background: url(../images/material/ic-arr-wht.png)no-repeat center; width: 27px; height: 14px;
                @include transition(.2s all $ease-out);
            }
       }
       &:hover{
            span{
              @include transform(scale(1.1));
                &:before{
                    left:5px;
                }
            }
       }
    }
    &.normal{
        padding: 0 80px; line-height: 45px; background: #088439; @include borderRadius(60px); font-family: $f-m;
        font-size: 17px; display: inline-block; text-transform: uppercase; color: #fff;
    }
    &.rounded2{
        line-height: 40px; font-size:14px; font-family: $f-m; padding: 0px 20px; @include boxSizing(border-box);
        letter-spacing: 2px; color: #fff; background: #A7A7A7; @include boxShadow(0px 5px 20px -15px #000);
        display: inline-block; @include borderRadius(32px); text-transform: uppercase;
        @include transition(.2s all $ease-out); position: relative; top: 0;
        &.green{
            background: #82B93B;
        }
        &:hover{
            @include boxShadow(0px 7px 20px -15px #000); background: #BFBFBF; top: 1px;
            &.green{
                background: #98C55E;
            }
        }
    }
    &.call{
        padding: 0px 40px 0px 55px; line-height: 40px; letter-spacing: 1px;  background: #82B93B; display: inline-block;
        color: #fff; font-family: $f-m;  text-transform: uppercase; @include borderRadius(32px); position: relative;
        @include boxShadow(0px 5px 20px -15px #000); @include transition(.2s all $ease-out);
        &:before{
            content:''; position: absolute; left: 13%; bottom: 0; top: 0; width: 14px; height: 14px;
            background: url(../images/material/ic-call.png)no-repeat center; margin: auto 0;
        }
        &:hover{
            background: #6B9831; @include boxShadow(0px 10px 30px -15px #000);
        }
    }
}
.backto{ font-size:16px; font-weight: bold; color:#093C81;   }
.t-right{ text-align: right !important; }
.t-center{ text-align: center !important; }
/* std-content
----------------------------------------------------------------------------------------------*/
.std-content{
    ul {
        margin: 0 0 30px 0;
        &.half{
             @extend .flex-list;
            li{ width: 50%; }
        }
        &.three{
            @extend .flex-list; 
            li{
                width: 30%;
            }
        }
        &.line{
            li{ 
                font-size: 16px; color:#777777;  position: relative;
                &:before{
                    content:'-'; position: absolute; left: 0; top: 0; font-size: 22px; color: #777777;
                    background: none; width: auto; height: auto;
                }
            }
        }
        li {
            position: relative; padding-left: 30px; font-size:16px; font-weight: 500; 
            color: #777777; font-weight: 400; margin: 10px 0; @include boxSizing(border-box);
            line-height: 26px;
            &:before{
                content:''; position: absolute; left: 0; top: 5px; background: url(../images/material/ic-ul.png)no-repeat center;
                width: 17px; height: 13px;
            }
            h5{ display: block; font-size: 14px; font-weight: bold; color: #9B9B9B; margin-bottom: 5px; }
            h4{ display: block; font-size: 16px; font-weight: 500; color: #333333; }
        }  
    }
    ol{
        margin: 15px 0px; list-style: decimal;
        li{ 
            font-size: 16px; color:#777777; margin: 0 0 10px 15px;   line-height: 26px; 

        }
    }
    table,thead,tbody,tfoot,tr,th,td{
        border: 1px solid grey;
    }
    table{
        margin: 10px auto;
        th,td{padding: 10px;line-height: 1.3;}
        th{font-weight: bold;}
    }
    p{ font-size: 16px; line-height: 26px; color:#777777; }
    .linkto{
        padding-left: 40px; display: block; font-size: 16px; font-weight: 400; color: #777777;
        margin-bottom: 20px; position: relative;   line-height: 26px; 
        &:before{
            content:''; position: absolute; left: 0; top: 0; background: url(../images/material/link-1.png)no-repeat;
            width: 24px; height: 24px;
        }
        &.email{
            &:before{
                background:url(../images/material/link-2.png)no-repeat;
            }
        }
        &.address{
            &:before{
                background:url(../images/material/link-3.png)no-repeat;
            }
        }
    }
    b{ font-size: 20px; font-weight: bold; color: #3C3C3C;  }
    .bold{ font-weight: bold; color: #333333; }
}


/* header
----------------------------------------------------------------------------------------------*/
header{
    height: 100px; position: fixed; top: 0; left: 0; right: 0;  z-index: 9;
    &.no-login {
        &:before {width: 23%;}
        &.float {
            &:before {width:100%;}
        }
        .btn.login {display: none;}
    }
    .btn.login {display: block;}
     &:before{ 
        content:''; position: absolute; right: 0; top: 0; width: 31%;  background: rgba(255,255,255,.8);
        height: 100px;  z-index: -1; @include transition(.4s all $ease-in);
        @include borderRadius(0px 0px 0px 100px);
    }
    .wrapper{ width: auto; margin: 0 100px; @include afterclear; }
    .logo{
        float: left; padding-top: 15px; display: inline-block; @include transition(.2s all ease-out); width: 162px;
        img{ @include transition(.4s all ease-out);  }
    }
    .right-header{ 
        float: right; position: relative; z-index: 1; padding-top: 30px; @include transition(.2s all $ease-out);
        @extend .flex-list;
        a{
             display: inline-block; margin: 0 5px; font-size: 16px; 
        }
        .burgermenu{ 
            display: inline-block;  font-size: 10px; color: #82B93B; vertical-align: top; 
            .togglemenu{
                display: block; width: 30px; height: 30px; margin-top: 5px;cursor: pointer;position: relative;
                span{
                    width: 100%; height: 3px; background: #82B93B; display: block; position: absolute;
                    @include borderRadius(5px); @include transition(.2s all $ease-out); right: 0;
                    &:nth-of-type(1){ top: 0; }
                    &:nth-of-type(2){ top: 7px; width: 60%; right: 0;}
                    &:nth-of-type(3){ top: 14px; }
                }
            }
            &:hover{
                .togglemenu{
                    span{
                        &:nth-of-type(1){ width: 20%; right: 0; }
                        &:nth-of-type(3){ width: 70%; right: 0; }
                    }
                }   
            }
        }
    }
    .nav-burgermenu{
        position: fixed;top: 0px;right: 0px;bottom: 0px;left: 0px;z-index: 1000;background: rgba(0,0,0,.5);display: none;
        overflow: hidden;
        .close-nav{
            position: absolute;top: 80px;right: 70px;width: 17px;height: 17px;z-index: 3;cursor: pointer;
            span{
                display: block;width: 100%;height: 2px;background: #fff;@include borderRadius(2px);
                position: absolute;top: 50%;margin-top: -1px;@include transform(rotate(45deg));
                @include transition(.2s all $ease-out);
                &:last-child{@include transform(rotate(-45deg));}
            }
            &:hover{
                span{
                    @include transform(rotate(-45deg));
                    &:last-child{ @include transform(rotate(45deg)); }
                }
            }
        }
        a{color: #FFFFFF;}
        .in-nav-burgermenu{
            width: 33%;background: rgba(20,51,93,.97);padding: 70px 0px;@include boxSizing(border-box);position: absolute;top: 0px;right: 0px;bottom: 0px;
            .menu{
                margin-bottom: 60px;
                >li{
                    font-size: 25px;margin-bottom: 20px;padding: 10px 70px; position: relative;
                    .drop-burger{
                        position: fixed;left: auto;top: 0px;bottom: 0px;right:0%;z-index: -1;opacity: 0;
                        visibility: hidden;@include transition(.3s all $ease-out); width: 33%;
                       &:before{ 
                            content:''; position: absolute; right: 0; top: 0; bottom: 0; display: block; background: rgba(17,44,80,.97); left: auto; 
                           width: 100%;
                        }
                        >ul{
                           /* background: #112C50;*/padding: 70px 0px;position: absolute;top: 0px;left: 0px;right: 0px;bottom: 0px;
                            li{margin-bottom: 20px;font-size: 22px;padding: 10px 70px;}

                            >li{
                                a{color: #7892B7;}
                                .sub-drop-burger{
                                    position: fixed;right: 0%;top: 0px;bottom: 0px;left: auto;padding: 70px 0px;z-index: -1;opacity: 0;visibility: hidden;@include transition(.3s all $ease-out);
                                    @include boxSizing(border-box); width: 34.5%;
                                     &:before{ 
                                        content:''; position: absolute; right: 0; top: 0; bottom: 0; display: block; background: rgba(15,39,72,.97); left: auto; 
                                       width: 100%; z-index: -1;
                                    }
                                    ul{
                                        li{
                                            &:hover{
                                                color: #088439;position: relative;
                                                &:before{content:"";position: absolute;top: 0px;left: 0px;bottom: 0px;background: #088439;width: 4px;}
                                                >a{color: #088439;font-weight: 700;}
                                            }
                                        }
                                    }
                                }
                                &:hover{
                                    color: #088439;position: relative;
                                    &:before{content:"";position: absolute;top: 0px;left: 0px;bottom: 0px;background: #088439;width: 4px;}
                                    >a{color: #088439;font-weight: 700;}
                                    .sub-drop-burger{opacity: 1;visibility: visible; right: 65.5%;}
                                }
                            }
                        }
                    }
                    &:hover{
                        color: #088439;position: relative;
                        &:before{content:"";position: absolute;top: 0px;left: 0px;bottom: 0px;background: #088439;width: 4px;}
                        >a{color: #088439;font-weight: 700;}
                        .drop-burger{opacity: 1;visibility:visible; right: 33%;}
                    }
                    &.active{
                        &:before{content:"";position: absolute;top: 0px;left: 0px;bottom: 0px;background: #088439;width: 4px;}
                        >a{color: #088439;font-weight: 700;}
                    }
                }
            }
        }
        .bottom-head{padding: 0px 70px;}
        .roundedlink{
            margin-bottom: 27px;
            a{
                display: inline-block;padding: 0px 30px 0px 50px;height: 40px;line-height: 40px;@include boxSizing(border-box);@include borderRadius(40px);
                background: rgba(255,255,255,.2);position: relative; overflow:hidden; z-index: 1;
                &:before{
                    content:"";position: absolute;top: 0;left: 22px;width: 20px;height: 20px;
                    bottom: 0; margin: auto 0;
                }
                &:after{
                    content: ''; position:  absolute; top: 0; left: 0; right: 0;  width: 100%;
                    height: 0; background: #088439; z-index: -1; @include transition(.2s all $ease-out);
                }
                &:hover{
                    &:after{ height:100%; }
                }
                &.tosite{
                    &:before{background: url('../images/material/ic_tosite.png') no-repeat center;}
                    &:after{ background: #088439 ;  }
                }
                &.log{
                    &:before{background: url('../images/material/ic_log.png') no-repeat center;}
                }
            }
        }
        .searchbox{
            display: block;position: relative;margin-bottom: 40px;
            input[type=text]{
                background: transparent;border:none;border-bottom: 1px solid rgba(255,255,255,.3);
                width: 100%;display: block;height: 40px;font-size: 16px;color: #fff;padding: 0px 40px 0px 0px;
                @include boxSizing(border-box);
                @include andplaceholder{font-style: italic;color: rgba(255,255,255,.64);}
            }
            input[type=submit]{position: absolute;top: 0px;right: 0px;bottom: 0px;width: 30px;background: url('../images/material/ic_search.png')no-repeat center;border: none;cursor: pointer;}
        }
        .foot-head{
            display: table;width: 100%;
            .lang,.sosmedhead{display: table-cell;vertical-align: middle;}
            .lang{
                a{
                    font-size: 16px;margin-right: 10px;
                    &.active{font-weight: 700;}
                    &:last-child{margin-right: 0px;}
                }
            }
            .sosmedhead{
                text-align: right; width: 70%;
                a{
                    margin-left: 10px; 
                    img{ @include transition(.2s all $ease-out); }
                    &:first-child{margin-left:0px;}
                    &:hover{
                        img{
                            opacity: 0.8; @include transform(scale(1.2));
                        }
                    }
                }
            }
        }
    }

    &.float{
        height: 75px; @include boxShadow(0px 2px 18px -15px #000); 
        &:before{
            width:100%; @include borderRadius(0px); @include boxShadow(0px 0px 20px -15px #000);
            height: 75px; background: rgba(255,255,255,1);
        }
        .logo{
            padding-top: 10px; width: 120px; 
        }
        .right-header{ padding-top: 15px; }
    }
}
.share-medsos{
    position: fixed; top: 50%; right: 0; @include transform(translateY(-50%)); z-index: 7;
   padding: 5px 0px;  @include boxSizing(border-box);
    a{ 
        display: block; width: 35px; height: 35px; padding: 5px; text-align: center; @include boxSizing(border-box);
        border-bottom: 1px solid rgba(255,225,255,0.2);  background: #5CBC4D; 
        @include transition(.2s all $ease-out);
        &:first-child{ padding-top:5px; @include borderRadius(10px 0px 0px 0px); }
        &:last-child{  border-bottom:none; padding-bottom:5px; @include borderRadius(0px 0px 0px 10px); }
        &:hover{ background:#79C86D;  }
    }
}
/*popup Style
---------------------------------------------------------------------------------------------*/
.wrap-pop{
    position: fixed; height: 100%; top: 0; left: 0; right: 0; bottom: 0; width: 100%; background:rgba(0,0,0,0.9);
    z-index: 9999999; overflow-y: auto; display: none;
    .pop-inner{
        position: absolute; display: block; width:100%; height: 100%;
        .overlay-pop{
            position: absolute; left: 0; bottom: 0; right: 0; top: 0;  margin: auto;
        }
        .close{
            position: absolute; right: 0px; top: -60px; width:35px; height: 35px; cursor: pointer; 
            z-index: 8; cursor: pointer;@include borderRadius(100%); 
            &:after,&:before{
                content:''; width: 30px; height: 4px; background:#82B93B; display: block; @include transform(rotate(45deg)); top: 15px;
                position: absolute; left: 0; right: 0; margin: auto; @incliude borderRadius(5px);
                @include transition(.2s all ease-out);
            } 
            &:before{  @include transform(rotate(-45deg));   }
            &:hover{
                &:after{ @include transform(rotate(-45deg)); }
                &:before{ @include transform(rotate(45deg)); }
            }
        }
        .content-pop{
            width: 800px; margin: 10% auto; position: relative; z-index: 8; background: #fff; @include borderRadius(10px);
            @include afterclear; padding: 35px 40px; @include boxSizing(border-box);
        }
        .box-popup{ 
            overflow-y: auto; max-height: 400px;
            .list-popup{
                width: 100%; display: table;
                figure,.text-pop{ display: table-cell; }
                .text-pop{ width: 70%; vertical-align: top; padding-left: 40px; }
                figure{
                    img{ width: 100%; height: 100%; -o-object-fit: cover; object-fit: cover; @include borderRadius(10px); }
                }
            }
            h5{ font-size: 15px; line-height: 25px; font-weight: bold; color: #3996D9; margin-bottom: 5px; }
            h4{ font-size: 18px; line-height: 28px; font-weight: 500; color: #333333; margin-bottom: 10px; }10
            p{ font-size: 15px; line-height: 24px;  }
        }
    }
    &#testimonial{
        .pop-inner{
            .box-popup{
                .text-pop{ vertical-align: middle; }
                h4{ font-weight: bold; color: #111;  }
                .list-popup{
                    figure{
                        img{ -o-object-fit: contain; object-fit: contain; }
                    }
                }
            }
        }
    }
    &#milestone{
        .pop-inner{
            .box-popup{
                .list-popup{
                    figure{
                        img{ height: 200px; }
                    }
                    ul{
                        li{ 
                            font-size: 15px; line-height: 24px; padding-left: 15px; position: relative; 
                            margin-bottom: 5px;
                            &:before{ 
                                content:''; position: absolute; left: 0; top: 0; bottom: 0; margin: auto 0; 
                                width: 8px; height: 8px; background: #82B93B; @include borderRadius(100%);

                            }
                        }
                    }
                }
            }
        }
    }
    &#award {
        .pop-inner{
            .close {top: -40px;}
            .content-pop {width: 450px; margin: 6% auto;}
            .box-popup1{
                max-height: none; overflow: hidden;
                .list-popup{
                    display: block;
                    figure{
                        display: block;
                        img{height: auto; width: 100%;}
                    }
                }
            }
        }
    }
}
.box-list-popup{
    .list-popup{
        display: none;
        &.active{display: block;}
    }
}




/*home
---------------------------------------------------------------------------------------------*/
.banner-home{
    position: relative;height: calc(100vh - 175px);
    &.corpo{ height: 100vh; }
    .list-slider{ 
        position: relative; z-index: 1; 
    }
    .slider-home{
        figure{ 
            height: 100vh;
            img{ width: 100%; height: 100%; object-fit: cover; }
        }
    }
    .text{
        position: absolute; z-index: 1; @include transform(translateY(-40%)); top: 40%;
        left: 10%; width: 600px; z-index: 2;
        small{
            position: relative; padding-left: 60px; font-size: 15px; font-family: $f-m; color: #626262; 
            margin-bottom: 10px; display: block;
            &:before{ 
                content:''; width:50px; height: 1px; background: #626262; position: absolute; left: 0; top:0; 
                bottom: 0; margin: auto 0; 
            }
        }
        h2{ font-size:50px; color: #093C81; line-height: 65px; font-weight: bold; margin-bottom: 15px;  }
        p{ font-size:20px; color: #3C3C3C;  font-family: $f-m; }
        .date{ color:#093C81;   }
    }
    .wrap-tab{ height: 100%; }
    .tabing{
        position: absolute; bottom: 5%; left: 10%; 
        ol{
            li{ 
                display: inline-block; margin: 0 30px 0 0px; width: 188px; text-align: center; 
                padding:15px 0; border-bottom: 1px solid #676767; 
                position: relative;  counter-increment: a; cursor: pointer;
                @include transition(.2s all ease-out);
                &:hover,&.active{
                    border-bottom: 3px solid #07207B;
                   a{
                     color:#07207B;
                   }
                   span{ background-position: right !important; }
                }
               &:last-child{
                    margin-right:0;
               }
                a{
                    font-size: 15px; font-family: $f-m; color: #676767; text-transform: uppercase;
                    padding-left: 35px; position: relative; text-align: center; @include transition(.2s all ease-out);
                     &:before{
                        content:'0'counter(a); position: absolute; left: -18px; top: -5px;  font-size: 10px; font-family: $f-m;
                    }
                }
                span{ 
                    display: inline-block; position: absolute;left: 0; top: 0; bottom: 0; 
                    width: 30px; height: 30px; margin: auto 0;
                }
            }
        }
    }
    .slider-small{
        position: absolute;  bottom:5%; right: 0; z-index: 2; width: 230px;
        .list-slider{
            width: 190px; margin-right: 20px;
            figure{
                @include borderRadius(10px); overflow: hidden;
                img{ opacity: 0.4; @include transition(.2s all $ease-out); cursor: pointer;}
            }
            &:hover{
                figure{
                    img{ @include transform(scale(1.1)); }
                }
            }
        }
        .slick-active{
            figure{
                img{ opacity: 1; }
            }
        }
        .slick-arrow{
            position: absolute; left: -10%; top: 35%; bottom: 0;
            font-size: 0; border: none; background: url(../images/material/arr-slider-s.png)no-repeat center;
            width: 7px; height: 14px; cursor: pointer; background-size: 100% 100%;
            opacity: .4;
            &.slick-next{
                @include transform(rotate(180deg)); top: 55%; left: -8%;
            }
        }
    }
    .wrap-tab {
        .content-tabbing {display: none;}
    }
    &.consumer{
        
    }
}
 .ttl-content{
    padding-bottom: 40px; margin-bottom: 50px; position: relative;
    &:after{ content:''; position: absolute; left:-50px; bottom:0; width: 110px; height: 1px; background:#093C81;  }
    h5{ font-size:16px; color: #093C81; text-transform: uppercase; letter-spacing: 1px; margin-bottom: 10px; }

}
.top-form-calculator{
    padding:0px 0px 10px 35px;
    label{color: #fff; margin-right: 80px;}
}
.form-calculator{
    &.fixed{
       display: none; padding-top: 20px;

       select{ 
            width:180px; padding: 0 10px; font-size: 14px; 
        }
       input{ width: 300px; }
       .bottom-form-calculator{
            input[type='text']{ width: 250px; font-size: 14px; }
       }
       button{ right: 8px; }
       div{
            &:nth-child(2){
                select{ width: 130px; }       
            }
       }
    }
}


.bottom-form-calculator{
    background:#fff;@include borderRadius(30px);height: 50px;@include boxSizing(border-box);padding: 0px 0px 0px 20px;position: relative;
    width:70%;
    input[type=number],select{
        height: 50px;border: none;background: transparent;padding: 0px;width: 205px;font-size: 17px;font-family: $f-m;color: #A0A0A0;}
    select{ 
        padding:0px 25px 0px 15px; 
        background: url(../images/material/arr-select.png)no-repeat 95% center;
    }
    input[type='text']{ 
        height: 50px;border: none;background: transparent;padding: 0px 0px 0px 20px; font-size: 17px;font-family: $f-m;color: #A0A0A0; 
        width: 250px;
    }
    > div{ 
        display: inline-block; position: relative;  
        &:after{ 
            content:''; position: absolute; right: 0; top: 0; bottom: 0; margin: auto 0; 
            background: #C7C7C7; width: 1px; height: 25px;
        }
    }
    button{
        height: 40px;position: absolute;top: 5px;bottom: 5px;right: 16px;@include borderRadius(40px);width: 180px;color: #fff;
        background:#2A9239;letter-spacing: 1px;font-size: 14px;text-transform: uppercase;@include boxShadow(0px 3px 30px -15px #000);
        display: inline-block; border: none; cursor: pointer;padding-left: 25px; @include transition(.2s all $ease-in);
        &:before{
            content:''; position: absolute; left: 20%; top: 0; bottom: 0; margin: auto 0; 
            background: url(../images/material/ic-calcu.png)no-repeat center;
            width: 16px; height: 20px; @include transition(.28s all $ease-out);
        }
        &:hover{
            background: #3D9B4B;  @include boxShadow(0px 3px 30px -10px #000);
            &:before{
              
            }
        }
    }
    @include placeholder{ color: #A0A0A0; }
}
.home{
    padding: 100px 0 60px; @include boxSizing(border-box);
    h2{ font-size: 40px; line-height: 50px; }
    h3{ font-size:35px; line-height: 45px;  }

    &.calculator{
        height: 175px;  padding-top: 15px; @include boxSizing(border-box); 
        h3{ color: #fff; margin-bottom: 10px; }
        &:before{ 
            content:''; position: absolute; left: 0; top: 0;bottom: -1px; background: url(../images/material/bg-green.png)no-repeat bottom; 
            width: 690px; z-index: 1; height:100%; background-size: cover;
        }
        &:after{ content:''; width: 75%; height: 100%;background: #088439; position: absolute; top: 0; right: 0;}

        .wrapper{ display: table; z-index: 2; position: relative; }
        .calculator-left,.calculator-right{ display: table-cell; vertical-align: middle;  }
        .calculator-left{ 
            width: 60%; padding-right: 25px; position: relative; 
            &:after{ 
                content:''; position: absolute; right: 0; top: 0; bottom: 0; width: 1px; height: 100%;
                background: rgba(255,255,255,0.3);
             }
        }
        .wrapper{ width: auto; margin: 0px 40px 0 300px; }
        .calculator-right{
           vertical-align: middle;
            h4,a{ display: table-cell; vertical-align: middle; }
            h4{ padding: 0px 40px; @include boxSizing(border-box); }
            a{ position: relative; width: 250px; line-height: unset; text-align: center; }
        }
        .top-form-calculator,.bottom-form-calculator{ width: 100%; }
        
        
        h4{ font-size:20px; color: #fff; line-height: 30px; margin-bottom: 20px;   }
    }
    &.services{
        position: relative;
        &:after{ 
            content:''; position: absolute; left: 0; right: 0; width: 100%; height: 305px; bottom: 0;
            z-index: -1;
            background: rgba(26,87,173,1);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(26,87,173,1)), color-stop(100%, rgba(9,59,129,1)));
            background: -webkit-gradient(linear, left top, left bottom, from(rgba(26,87,173,1)), to(rgba(9,59,129,1)));
            background: linear-gradient(to bottom, rgba(26,87,173,1) 0%, rgba(9,59,129,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a57ad', endColorstr='#093b81', GradientType=0 );
           
        }
        .services-list{
            margin: 0 -15px;
            @include afterclear; 
            .ls-services{
                float: left; width:calc(33.333% - 30px); margin:0 15px; cursor: pointer;
                @include borderRadius(10px); overflow: hidden; position: relative;
                &:last-child{ margin-right:0; }
                &:hover{
                    figure{
                        img{ @include transform(scale(1.05)); }
                    }
                    .desc{
                        &:before{
                            @include transform(scale(1.2));
                        }
                    }
                }
                figure{
                    position: relative;
                    img{ @include transition(.2s all ease-out); }
                    &:before{ 
                        content:''; position: absolute; left: 0; top: 0; bottom: 0; right: 0;  
                        background: url(../images/material/shd-service.png)no-repeat center;
                        @include transition(.2s all ease-out); width: 100%; z-index: 1;
                    }
                }
                .desc{
                    position: absolute; left: 20px; bottom: 20px; width: 120px; z-index: 2;
                    h5{ color: #fff; font-size: 20px; font-weight: bold; line-height: 30px; }
                    &:before{
                        content:''; position: absolute; width: 580px; height: 375px; @include borderRadius(100%);
                         left:-220px; bottom: -280px; background: #6FB03A; z-index: -1;
                         @include transition(.2s all ease-out);
                    }
                }
            }
        }
    }
    
    &.product{
        background: #F5F5F5;
    }
    &.aboutus{
        .wrap-left-right{
            display: table; width: 100%;
            .left,.right{ float: none; display: table-cell; vertical-align: middle; }
            .right{ width: 60%; padding-left:80px; @include boxSizing(border-box); }
        }
    }
    &.testimonial{
        position: relative; background: url(../images/content/bg-testi.png)no-repeat center;
        background-size: cover; 
        .slider-testi{
            padding-bottom: 80px; 
            .slick-track{ display: flex; }
            .list-slider{
                width: 50%; margin: 10px 12.5px;  text-align: center; background: #fff;
                padding: 40px 65px; @include boxSizing(border-box); position: relative;
                @include boxShadow(-2px 0px 30px -25px #000); height: auto;
                &:before{ 
                    content:''; position: absolute; top: 0; left: 0; right: 0; background: url(../images/material/border-top-testi.png)no-repeat; 
                    height: 10px; width: 100%;
                }
            }
            .profile{
                figure{
                    width: 74px; height: 74px; @include borderRadius(100%); overflow:hidden;
                    margin: 0 auto 20px;
                }
                .name{ 
                    font-size: 16px; font-family: $f-m;  font-style: italic; font-weight: 200;  display: block;
                    color: #3C3C3C; margin-bottom:5px;
                }
                .pos{
                    font-size: 16px; font-weight: bold; font-family: $f-m; color: #3C3C3C;
                }
            }
            .qts{
                p{ 
                    font-size: 14px; font-family: $f-l; color: #5F5F5F; line-height: 24px; position: relative; padding-top: 30px; 
                    
                }
            }
            .slick-arrow{
                position: absolute; left: -45px; top: 0; bottom: 0; margin: auto 0; font-size: 0; border: none;
                background: url(../images/material/arr-paging.png)no-repeat center; width: 13px; height: 20px;
                cursor: pointer; z-index: 2;
                &.slick-next{ left: auto; right: -45px; @include transform(rotate(180deg)); }
            }
            .slick-dots{
                position: absolute; left: 0; right: 0; bottom: 0;  margin: 0 auto; text-align: center;
                li{ 
                    display: inline-block; margin: 0 10px; position: relative; width: 17px; height: 16px;
                    &:before{ 
                        content:''; position: absolute; left: 0; top: 0; bottom: 0; right: 0; margin: auto; 
                        border: 1px solid transparent; @include borderRadius(100%);
                        width: 16px; height: 16px; display: block;
                    }
                    button{ 
                        width: 8px; height: 8px;  font-size: 0; @include borderRadius(100%); 
                        padding: 0; border: none; background: #093C81; position: absolute; top: 0; bottom: 0; left: 0; right: 0;
                        text-align: center;display: block; @include transform(translateX(64%)); margin: auto 0;
                       cursor: pointer;
                    }
                    &.slick-active{
                        &:before{ border-color:#093C81 ; }
                    }

                }
            }
        }
    }
}

.product{
      padding: 80px 0px 0px; min-height: 300px;
      &.no-pad {padding-top: 20px;}
    h3{ 
        color:#07207B;  
        &.empty{ font-weight: bold; font-style: italic;  }
    }
    .ttl-filter{
        display: table; width: 100%; margin-bottom: 35px;
        .lf,.rg{ text-align: left; display: table-cell; vertical-align: middle; }
        .rg{ text-align: right;  }
        label{ font-size:12px;  color:#585858; margin-right: 10px;  font-weight: 400;  }
        select{
            width: 190px; @include boxSizing(border-box); line-height: 40px;
            @include borderRadius(20px); border: 1px solid #82B93B; padding: 0 40px 0 20px;
            background: #fff url(../images/material/arr-select.png)no-repeat 90% center;
            font-size: 12px; color: #3C3C3C;
        }
        h4{ font-size: 20px; font-weight: bold; }
        .viewall{ font-size: 15px; text-transform: uppercase; font-weight: bold; }
    }
}
.partner{
    overflow: hidden;
    @include afterclear;
    .tab-partner{
        .btn-tab-partner{
            background: #07207B; position: relative;   width: 200px; height: 100px; float: left;
            @include borderRadius(0px 50px 50px 0px);
            &:before{ 
                content:''; position: absolute; right: 100%; top: 0; bottom: 0; background: #07207B;  
                height: 100%; width: 1000px;
            }
            a{ 
                width:80px; height: 80px; @include borderRadius(100%); background: #091B5D;
                display: table; vertical-align: middle;margin-top: 10px;float: left;
                margin-right: 10px;  text-align: center;
                small{ display: inline-block;width: 30px;height: 30px;background-position: left center;margin-bottom: 3px;margin-top: 3px;}
                > span{ 
                    font-size: 10px; text-align: center; display: block; display: table-cell;vertical-align: middle;color: rgba(255,255,255,.5);
                    > span{display: block;}
                }
                &.active{
                    background: #fff;
                    small{background-position: right center;}
                    > span{color: #07207B;}
                }
            }
        }
    }
    .sliderpartner{ 
        float: right; width: 70%; display: none;
        .ls-partner{
            position: relative; padding:0 40px; @include boxSizing(border-box);
            img{ 
                filter: grayscale(100%); -webkit-filter: grayscale(100%); cursor: pointer;
                height: auto;  
                @include transition(.2s all ease-out);
                &:hover{
                     filter: grayscale(0); -webkit-filter: grayscale(0);
                }
                &.act{
                    filter: grayscale(0); -webkit-filter: grayscale(0);
                }
            }
            .slick-arrow{ 
                position: absolute; right: 0; top: 0; bottom: 0; margin: auto 0; z-index: 2;
                border: none;  font-size: 0; background: url(../images/material/arr-slide-partner.png)no-repeat center;
                cursor: pointer;
                &.slick-prev{
                    right: auto; left: 0; @include transform(rotate(-180deg));
                }
            }
            .slick-current{
                filter: grayscale(0); -webkit-filter: grayscale(0);
            }
        }
    }
}
.wrap-product{
    @extend .flex-list; width: 100%;
    @include afterclear;
    .ls-product{
        width:calc(25% - (90px / 4)); margin:0 30px 30px 0; position: relative; top: 0;
        background: #fff; @include borderRadius(10px); @include transition(.2s all ease-out);
        overflow: hidden;
        &:nth-child(4n){ margin-right: 0; }
        &:hover{
            @include boxShadow(2px 2px 20px -14px #000); top: 5px;
        }
        img{ width: 100%; }
    }
    .label{ 
        position: absolute; left: 0; top: 15px; background: #82B93B;
        line-height: 30px;  padding: 0 20px 0 25px; font-size: 14px; color: #fff;
        font-family: $f-l; font-weight: bold; @include borderRadius(0 20px 20px 0);
        span{ font-size: 9px; } 
    }
    .desc{
        padding: 20px; @include boxSizing(border-box);
    }
    h6{ font-size: 12px; color: #6AAE3A; font-weight: bold;  margin-bottom: 15px;}
    h5{ 
        font-size:18px; line-height: 24px; color: #333333; padding-bottom: 10px;  
        position: relative; margin-bottom: 15px;
        &:before{
            content:''; position: absolute; left: 0;bottom: 0; width: 40px; height: 1px; background: #093C81;
        }
    }
    .price{
        font-size: 20px; font-family: $f-l; font-weight: bold; color: #3C3C3C; margin-bottom: 10px;
    }
    .tdp-price{
        font-size: 12px; color: #717171; font-family: $f-l; line-height: 22px;
    }
    &.three{
        .ls-product{
            width: calc(33.333% - (30px / 3)); margin: 0 15px 30px 0;
             &:nth-child(4n){ margin-right: 15px; }
             &:nth-child(3n){ margin-right: 0px; }
        }
    }
}


/* middle
----------------------------------------------------------------------------------------------*/
.nopaddbot{ padding-bottom: 0px !important; }
.nopaddtop{ padding-top: 0 !important; }
.act-slider-mobile{
   li{

   }
}
.middle{
    padding: 50px 0 100px; @include boxSizing(border-box);
    &.bgbot{
        position: relative; padding-bottom: 60px;
       &:before{
            content:''; position: absolute; bottom: 0; left: 0; right: 0; background: url(../images/content/bg-bottom.png)no-repeat;
            height: 734px; z-index: -1;
       }
    }
    
    &.nobanner{ padding: 130px 0 80px; }
    .wrapper{ @include afterclear; }
    .mCSB_inside > .mCSB_container { margin-right: 0; padding-right: 20px; }
    aside{
        width:290px; position: relative; float: left;
         z-index: 1;
         .sub-trgr{ display: none; }
        ul{
            position: relative; z-index: 1;
            li{ 
                display: block; text-align: right; margin: 10px 0; position: relative; padding-left: 20px;
                a{ 
                    font-size: 17px; color: #BCD5F8; line-height: 27px; 
                    @include transition(.2s all ease-out);
                    &:hover,&.active{ color:#fff;  }
                }
                &.have-child{
                    padding-left: 0;
                    >a{
                        position: relative; padding-left: 20px;
                        &:before{ 
                            content:'+'; position: absolute; left:0px; top: 0px; font-size:30px;  font-weight: bold; 
                            color: #0CC154; @include transition(.2s all ease-out); line-height: 20px;

                        }   
                    }
                     > ul{
                        display: none;
                        li{
                            margin: 5px 0;
                           >a{ 
                                font-size: 15px; line-height: 25px; position: relative;
                                &:hover,&.active{
                                    color:#0CCC58; 
                                  }
                            }
                            &.sub-child{
                                a{
                                    position: relative;
                                    &:before{ 
                                        content:'+'; position: absolute; left:-20px; top: 50%; font-size:30px; line-height: 1px;   
                                        font-weight: bold; color: #0CC154; @include transition(.2s all ease-out);
                                        bottom: 0; @include transform(translateY(-50%));
                                    }
                                }
                                ul{
                                    display: none;
                                    li{ 
                                    position: relative; 
                                        a{ 
                                            font-size: 15px; color: #64A5FF; position: relative; padding-right: 25px;
                                            &:before{ 
                                                content:''; position: absolute; right: 0px; width: 0px;
                                                height: auto; top: 50%; bottom: 0; background: #fff; @include transform(translateY(-50%));
                                                @include transition(.2s all ease-out);
                                             }
                                            &:hover,&.active{ 
                                                color:#fff;  
                                                &:before{ width:4px; }
                                            }
                                        }
                                    }
                               }
                               &.action{
                                    >a{
                                        &:before{ content:'-'; }
                                    }
                                }
                            }
                        }
                    }
                    &.act{
                        >a{
                            &:before{ content:'-'; }
                        }
                    }
                }
            }

        }
        &.fixed{
            position: fixed; top:75px; left: 19.3%; width:290px; 
        }
        &.botzero{ position: absolute; bottom:90px; top: auto; left: 19.3%; }
        .tittle{
            position: absolute; top: 0; right: -10px; left: 35px; background: #088439;
            font-size: 24px; font-weight: 800; padding: 35px 50px 35px 0; @include boxSizing(border-box);
            text-align: right; vertical-align: middle; color: #fff; z-index: 2;
            &:before{
                content:''; position: absolute;right: 100%; top: 0;bottom: 0; 
                background: url(../images/content/bg-path.png)no-repeat center;width: 94px; height: auto;
                 background-size: 100% 101%;
            }
        }
        .navigasi{
            position: relative;  padding: 120px 0px 100px 30px; @include boxSizing(border-box); min-height:450px;
             max-height:690px; @include borderRadius(0px 0px 0px 40px); overflow: auto; -webkit-mask-image: -webkit-radial-gradient(white, black); 
            background: rgba(17,80,167,1);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(17,80,167,1)), color-stop(100%, rgba(19,60,122,1)));
            background: -webkit-gradient(linear, left top, left bottom, from(rgba(17,80,167,1)), to(rgba(19,60,122,1)));
            background: linear-gradient(to bottom, rgba(17,80,167,1) 0%, rgba(19,60,122,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1150a7', endColorstr='#133c7a', GradientType=0 ); 
            &:after{
                content:''; position: absolute; left: 0; bottom: 0; right: 0; 
                background: url(../images/content/bg-aside.png)no-repeat bottom;height: 138px; 
                background-size: cover;
            }
        }
    }  
    
}
.content-middle{
     @include boxSizing(border-box); float: right; width: 790px; position: relative;
     h2{  }
    &.full{ padding-left: 0; }
    .abs-all {
        position: absolute; font-size: 14px; font-weight: 600; text-transform: capitalize; color: #333; left: auto; right: 0; top: 8px;
        &:hover {text-decoration: underline;}
    }
} 
.tittle-middle{
    padding-bottom: 30px; margin-bottom: 35px; position: relative;
    &:after{ 
        content:''; position: absolute; bottom: 0px; left: -30px; width: 108px; height: 1px; background: #093C81;  
    }
    h5{ font-size:20px; line-height: 30px; font-weight: bold; color: #093C81; margin-bottom: 20px;  }
    h2{ font-size: 30px; line-height: 40px;  color: #3C3C3C; }
}

.img-middle{
    position: relative;@include borderRadius(20px); overflow: hidden; margin: 30px 0px 40px;
    img{ width: 100%; height: 100%; -o-object-fit: cover; object-fit: cover; }
    .desc{
        position: absolute; right: 30px; bottom: 0;  background: rgba(112,173,53,0.95);
        width: 320px; padding: 35px 30px; @include boxSizing(border-box);
        @include borderRadius(40px 0px 0px 0px);
        h3{ font-size:20px; color: #fff; line-height: 30px;text-align: right; }
    }
    .rounded-desc{
        position: absolute; left:30px; bottom: 20px;  right: 30px; width: auto;
        z-index: 1;
        &:before{
            content:''; position: absolute; left: -200px; bottom: -310px; width: 580px; height: 375px;
            background: #489F3A; @include borderRadius(100%); z-index: -1;
            @include transition(.4s all ease-out);
        }
        h5{ font-size: 20px; font-weight: bold; color: #fff; margin-bottom: 15px; }
        p{ font-size: 16px; line-height: 26px; color: #fff;  }
    }
    &.detail{
        .rounded-desc{
            &:before{
                bottom:-280px;
            }
        }
    }
    &.big{
        display: block;
        .desc{
           width: auto; left: 60px; right: 60px; @include borderRadius(0px 40px 0px 0px); bottom: 2px;
           h3{ text-align: left; font-weight: 500; }
           h5{ font-size: 15px; font-weight: bold; line-height: 20px; color: #fff;}
           p{ font-size:16px; color: #fff; line-height: 24px; margin-bottom: 0; }
        }
    }
}

.twobox-middle{
    @extend .flex-list; width: 100%;
    .box{
        width: 47%; margin-right: 6%; position: relative; overflow: hidden;
        @include borderRadius(20px); cursor: pointer; -webkit-mask-image: -webkit-radial-gradient(white, black);
        &:nth-child(2n){ margin-right: 0; }
        img{ width: 100%; }
        &:hover{
            .rounded-desc{
                &:before{
                    @include transform(scale(3));
                }
                .text-hover{ height: 160px; }
            }
        }
    }
    .rounded-desc{
        position: absolute; left:30px; bottom: 20px;  right: 30px; width: auto;
        z-index: 1;
        &:before{
            content:''; position: absolute; left: -200px; bottom: -310px; width: 580px; height: 375px;
            background: #489F3A; @include borderRadius(100%); z-index: -1;
            @include transition(.4s all ease-out);
        }
        h5{ font-size: 20px; font-weight: bold; color: #fff; margin-bottom: 15px; }
        p{ font-size: 16px; line-height: 26px; color: #fff;  }
    }
    .text-hover{
        height: 0; overflow: hidden; @include transition(.3s all ease-out);
    }
}
.threebox-product{
    .tittle-prod{
        display: table; width: 100%;
        .left,.right{ display: table-cell; float: none; vertical-align: middle; }
    }
}

.pagging{ 
    text-align: center;  margin: 40px 0 60px; width: 100%;
    @include afterclear;
    a{ 
        font-family: $f-l; font-size: 16px; margin: 0 15px; line-height: 20px; color: #777777; 
        @include transition(.2s all ease-out); display: inline-block; font-weight: bold;
        &:hover,&.active{
            color:#1A4786; font-weight: bold;
        }
    }
    .arr{
        background: url(../images/material/arr-paging.png)no-repeat center;
        float:left; display: inline-block; width: 13px; height: 20px;
        &.next{
            float:right; @include transform(rotate(180deg));
        }
    }
}

.sec-bggrey{
    padding: 40px 0 30px; @include boxSizing(border-box); background: #F1F4F8;
    margin: 30px 0px 50px;
    + .middle.bgbot {padding-top: 0;}
}
.detail-page{
    @include afterclear; @extend .flex-list; min-height:400px;
    @include borderRadius(10px); overflow: hidden; margin-bottom: 40px;
    .det-left{
        float: left; width: 40%; position: relative;
       figure{
            height: 400px; 
            img{ height: 100%; -o-object-fit: cover; object-fit: cover; }
        }
        .slider-figure-detail{
            .slick-dots{
                position: absolute; bottom: 5%;  left: 0; right: 0; margin: 0 auto; text-align: center;
                li{ 
                    display: inline-block; margin: 0 4px;
                    button{ 
                        font-size: 0; border: none; width: 12px; height: 12px; @include borderRadius(100%);
                        cursor: pointer; background: #fff; @include transition(.2s all $ease-out);
                     }
                     &.slick-active{
                        button{ width:16px; height: 16px; background: #093C81;  }
                     }
                }
            }
        }
    }
    .det-right{
        float: left;width: 60%;
        background: rgba(17,80,167,1);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(17,80,167,1)), color-stop(100%, rgba(19,60,122,1)));
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(17,80,167,1)), to(rgba(19,60,122,1)));
        background: linear-gradient(to bottom, rgba(17,80,167,1) 0%, rgba(19,60,122,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1150a7', endColorstr='#133c7a', GradientType=0 ); 
        padding: 80px 50px 80px 80px; @include boxSizing(border-box);
    }
    h4{ 
        font-size:20px; font-weight: bold; padding-bottom: 20px; margin-bottom: 20px; color: #fff;  
        position: relative;
        &:before{
            content:''; position: absolute; left: -30px; width: 108px; bottom: 0; background: #fff; height: 1px;
        }
    }
    h3{ font-size: 28px; font-weight: bold; color: #7EB4FF; font-family: $f-l; margin-bottom: 20px; }
    .row{
        span{ 
            display: inline-block; color: rgba(255,255,255,0.6); 
            font-family: $f-l; font-size: 15px; 
        }
        small{ font-size: 9px; font-weight: bold; font-family: $f-l; }

        .tdp{ 
            padding-right: 10px; position: relative; margin-right: 5px;
            &:after{ 
                content:''; position: absolute; right: 0; top: 0; bottom: 0; margin: auto 0; 
                width: 1px; height: auto; background: #707070;
            }
        }
        .price{ 
            font-size:14px; font-weight: bold; color: #fff; background:rgba(255,255,255,0.14%);   
            line-height: 30px; @include borderRadius(20px); padding: 0 20px; @include boxSizing(border-box);
            margin-left: 20px;
        }
    }
     .btn{ 
        display: block; font-size: 17px; font-family: $f-m; text-transform: uppercase; color: #fff; 
        line-height: 45px; background: #088439; @include borderRadius(30px); 
         text-align: center; margin-top: 35px; @include transition(.2s all ease-out); position: relative;
         &:hover,&:active{
            background:#1E8F4B; 
         }
         &:active{ top: 2px; }
    }
}
.banner-middle{
    figure{
        height: 560px; position: relative;
        &:before{ 
            content:''; position: absolute; top: 0; left: 0; right: 0; width: 100%; height: 235px;
            background: url(../images/content/shd-banner-middle.png)no-repeat center;
        }
        img{ height: 100%; width: 100%; -o-object-fit: cover; object-fit: cover; }
    }
    &.banner-detail{
        figure{
            height: auto; width: 100%; padding-top: 100px; position: relative;
            &:before{ display:none; }
            img{ width: 100%; }
            .tittle-banner-detail{ 
                position: absolute; top: 50%; left: 0; right: 0; margin: 0 auto; @include transform(translateY(-50%));
                text-align: center;
                .date{ font-size:20px; font-weight:bold; color:#3996D9; margin-bottom: 20px; display: block;  }
                h5{ font-size:30px; color: #DFEAFA; }
            }
        }
    }
}
.table-middle{
    &.corpo{
        width: 100%; overflow: auto;
        table{width: 100%;}
        thead{
            th{ 
                padding: 15px; text-align: center; font-size: 15px; line-height: 25px;
                &:first-child{ text-align:left; }
            }
        }
        tbody{
            td{ 
                padding:15px; font-size: 15px; line-height: 25px;  text-align: center;
                &:first-child{ width:auto; }
            }
        }
    }
    table{ width: 100%; }
    tbody{
        tr{
            &:nth-child(even){ background: #E7ECF2; }
        }
        td{ 
            padding: 20px 40px; @include boxSizing(border-box); text-align: center;  vertical-align: middle;
             font-size: 17px; font-weight: 500; font-family: $f-l; color: #555555; line-height: 27px;
            &:first-child{ text-align:left; width: 80%;}
        }
    }
    thead{
        tr{ background:#1150A7;    }
        th{ 
            color: #fff; font-size: 17px; font-weight: 500; font-family: $f-l; text-align: left; 
            padding: 20px 40px; @include boxSizing(border-box); vertical-align: middle; 
            &:first-child{
                @include borderRadius(10px 0px 0px 0px); 
            }
            &:last-child{ 
                @include borderRadius(0px 10px 0px 0px); 
            }
        }
    }
}


.tittle-form{
    position: absolute; right: 30px; bottom: 0; 
    line-height: 90px; font-size: 20px; font-weight: 500; color: #fff;
    @include borderRadius(20px 0px 0px 0px); padding: 0 35px; @include boxSizing(border-box);
    background: rgba(130,185,59,1);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(130,185,59,1)), color-stop(100%, rgba(62,152,30,0.9)));
    background: linear-gradient(135deg, rgba(130,185,59,1) 0%, rgba(62,152,30,0.9) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#82b93b', endColorstr='#3e981e', GradientType=1 );
    &.number{
        line-height: normal; height: 90px; display: table;
        a{ 
            display: block; font-size: 20px; color: #fff; padding-left: 25px; margin-top: 10px; 
            font-family: $f-l; font-weight:400; position: relative;
            &:before{ 
                content:''; width: 21px; height: 21px; position: absolute; left: 0; top: 0; bottom: 0; 
                background: url(../images/material/ic-phone.png)no-repeat center;
            }
        }
        .wrap-contact{
            display: table-cell; vertical-align: middle;
        }
    }
    &.top{
        top: -1px; left: 0; right: auto; bottom: auto;  @include borderRadius(20px 0px 20px 0px);
        span{ 
            padding-left: 35px; position: relative;  
            &:before{ 
                content:''; position: absolute; left: 0; top: 0; bottom: 0; width: 27px; height: 27px; 
                background:url(../images/material/login-wht.png)no-repeat center;
            }
        }
    }
}
.tittle-contactus{
    position: absolute; right: 0; top: 0;text-align: center; padding: 20px 100px 0px 0px; 
    @include boxSizing(border-box);
    small{ color: #fff; display: block; font-size: 16px; line-height: 24px; margin-bottom: 5px; }
    a{ 
        font-size:20px; line-height: 30px; font-weight: bold; color: #fff; display: block; font-family: $f-l; 
        position: relative; padding-left: 25px; @include boxSizing(border-box);
        &:before{ 
            content:''; position: absolute; left: 0; top: 0; bottom: 0; background: url(../images/material/ic-call.png)no-repeat center;
            width: 14px; height: 14px; margin: auto 0;
         }
    }
}
.sec-form{
    &.padbot200{ padding-bottom: 250px; }
    &.nobanner{
       position: relative;
        .tittle-form{
            top: 0; right: auto; left: 0; bottom: auto;
            @include borderRadius(10px 0px 40px 0px);

        }
        .content-form{
             padding-top: 150px; @include boxSizing(border-box); @include borderRadius(10px); overflow:hidden;
        }
    }
    figure{
         position: relative; overflow: hidden; @include borderRadius(10px 10px 0px 0px);
       
    }
    
    .content-form{
        background: #1150A7; padding: 90px 60px 40px; @include boxSizing(border-box);
         @include borderRadius(0px 0px 10px 10px); overflow:hidden;
    }
    label{ 
        display: block; margin-bottom: 10px;  font-size: 14px; font-family: $f-m;
        color: #fff;
    }
    input[type='text'],
    input[type='number'],
    input[type='email'],input[type='file'],select{ 
        width: 100%; @include boxSizing(border-box); 
        border: 1px solid transparent; height: 45px; @include borderRadius(60px); padding: 0 30px; 
         font-size: 16px; color: #3B3B3B; font-family: $f-m;
         &:focus{ border:2px solid #82B93B; }
    }
    textarea{ 
        width: 100%; resize: none;  @include borderRadius(30px); padding: 15px 30px; @include boxSizing(border-box);
        height: 110px; font-size: 16px; color: #3B3B3B; font-family: $f-m;
     }
    .fileinput{ 
         background: #fff url(../images/material/ic-fileupload.png)no-repeat 95% center; @include boxSizing(border-box);  border: none; width: 100% !important; height: 45px !important; @include borderRadius(60px); 
        line-height: 45px; padding: 0px 60px 0px 30px;  font-size: 16px; font-family: $f-m; color: #555555;
        span{
             font-size: 14px; font-family: $f-m; color: #A0A0A0;
        }
     }
     @include placeholder{
        font-size: 16px; color: #A0A0A0; font-family: $f-m;
     }
    select{
        background: #fff url(../images/material/arr-select.png)no-repeat right 25px center;
        padding-right: 60px;
    }
    input[type=submit]{ 
        height: 45px; font-size: 17px; @include borderRadius(30px); color: #fff; 
        width: 100%; background: #088439; border: none; cursor: pointer; @include transition(.2s all $ease-out);
        &:hover{
            background:#349A5D;
        }
    }
    input[type=radio]{
        width: 20px;height: 20px;background: url('../images/material/ic_radio.png') right center;display: inline-block;
        vertical-align: middle;margin-right: 5px; position: absolute; left: 0; top: 5px;
        &:checked{background-position:left center;}

    }
    .option{
        font-size: 16px;color: #fff;display: inline-block;vertical-align: middle;
        position: relative; padding-left: 25px; box-sizing: border-box; line-height: 26px;
        input[type=radio]{margin-top: -3px;}
    }
    .row{
        @include afterclear;
        .col{
            margin:0px 0px 30px; width: 100%; float: left;
            &.half{
                width: 48%; margin-right: 4%; 
                &:nth-child(2n){ margin-right: 0; }
            }
           
        }
    }
    .colthreeopt{
        @extend .flex-list;-webkit-box-pack:justify;justify-content:space-between;
        margin: 20px 0 0;
    }
    .colopt{
        h5{ font-size: 14px; color: #fff; margin-bottom: 20px; }
        .option{ width: 48%; margin-bottom: 20px; }
    }

}

.g-option {
    > label {margin-bottom: 13px;}
    .option {margin-right:40px; padding-left: 30px; margin-bottom: 20px;}
}
.promo-list{
    margin: 40px 0 60px;
    .list{
        @include afterclear; @extend .flex-list; min-height: 300px; margin-bottom: 30px;
        @include borderRadius(10px); overflow: hidden; @include boxShadow(0px 2px 20px -15px #000);
    }
    figure{
         width: 60%; float: left;
         img{ width: 100%; height: 100%; -o-object-fit: cover; object-fit: cover; }
     }
    .text{ 
        width: 40%; float: left; background: #093C81; 
        padding: 50px 40px 40px; @include boxSizing(border-box);
    }
    .date{ font-size: 15px; font-weight: bold; line-height: 22px; color:#3996D9; margin-bottom: 10px; }
    h5{ font-size:17px; font-weight: 500; color: #fff; line-height: 27px; margin-bottom:5px; }
    p{ font-size: 15px; line-height: 24px; color:#A8C7F7;  }
    
}

.wrap-acc-middle{
    .list-acc{ 
        margin-bottom:20px;  
        &.act,&:hover{
            .grid-acc{
                background: #1150A7;
                h5{ color: #fff;  }
                .loc{ color: #fff; }
            }
            .plus{
                span{
                    @inlcude transform(rotate(-25deg));
                    &:first-child{
                        @include transform(rotate(180deg));
                    }
                }
            }
        }
    }
    .grid-acc{ 
        padding: 20px 40px 20px 30px; @include boxSizing(border-box); background: #E7ECF2;
        @include borderRadius(10px); position: relative; cursor: pointer; @include transition(.2s all ease-out);
        h5{ font-size: 17px; line-height: 27px; color: #555; display: inline-block;  } 
        .plus{
            position: absolute; right: 30px; top: 0; margin: auto 0; bottom: 0; width: 12px; height: 12px;
            span{
                width:100%; height:3px; background:#0CC154; display: block;  position: absolute; top: 0; bottom: 0;
                margin: auto 0; @include transition(.25s all ease-out);
                @include transform(rotate(0deg));
                &:first-child{
                    @include transform(rotate(90deg));
                }
            }
        }
        .loc{ font-size:15px; font-weight: bold; font-family: $f-l; color: #1150A7; display: inline-block; margin-left: 10px; }

    }
    .content-acc{ 
        padding: 30px; @include boxSizing(border-box); display: none;
        h6{ font-size: 16px; font-weight: bold; color: #333333; margin-bottom: 10px; }
        p{ font-size:16px; line-height: 26px; color: #777777;  }
    }
    ol{
        margin: 15px 0px; list-style: decimal;
        li{ 
            font-size: 16px; color:#777777; margin: 0 0 10px 15px;   line-height: 26px;
            word-break: break-word; 
            ol{
                margin-left:5px;
            }
        }
    }
}
.maps-middle{
    iframe{
        width: 100%; height: 360px;
    }
}
.wrap-address{
    padding: 50px 0px;
    h5{ font-size: 20px; margin-bottom: 15px; font-weight: 500; color: #333333; }
    h6{ font-size: 16px; font-weight: bold; color: #0B3F86; margin-bottom: 15px; display: block; }
    p{ font-size: 16px; color: #777777; line-height: 28px; font-family: $f-l; }
    .list-addres{
        margin-bottom: 30px; @include afterclear;
    }
    .addres{ 
        float: left; width: 48%; margin-right: 4%; @include afterclear;
        &:nth-child(2n){ margin-right: 0; }
    }
    .ic{
       font-size: 16px; color: #777777; padding-left: 25px; font-family: $f-l; margin-bottom: 15px; display: block;
        position: relative; margin-right: 20px; 
        &:nth-child(2n){ margin-right: 0; }
        &:before{
            content:''; position: absolute; left: 0; top: 0; bottom: 0; width: 20px; height: 20px;
            margin: auto 0;
        }
        &.phone{
            &:before{ background: url(../images/material/ic-telepon.png)no-repeat; }
        }
        &.fax{
            &:before{ background: url(../images/material/ic-fax.png)no-repeat; }
        }
        &.email{
            &:before{ background: url(../images/material/ic-email.png)no-repeat; }
        }
    }
    .wrap-ic{
        @extend .flex-list; align-content: space-between;
    }
}
.login-home{
    height: 100vh; position: relative; z-index: 1; 
    .logo{ left: 8%; top: 5%; position: absolute; z-index: 2; }
    .slider-login{
        position: relative;
        .list{ position: relative; }
        .slick-dots{ 
            position: absolute; bottom: 5%; left: 8%; 
            li{ 
                display: inline-block;  margin: 0 10px 0px 0px;
                button{ 
                    font-size: 0; width: 10px; height: 12px; border:none; background:#707070; @include borderRadius(100%);  
                    display: block;
                }
                &.slick-active{
                    button{
                        background: #82B93B;
                    }
                }
            }
        }
    }
    figure{
      width: 100%;
        &:after{ 
            content:''; position: absolute; bottom: 0; left: 0; right: 0; height: 418px; width: 100%;
            background: url(../images/material/shd-login.png)no-repeat;
            background-size: cover;
         }
        img{ height: 100%; width: 100%; -o-object-fit: cover; object-fit: cover;}
    }
    .text{ 
        position: absolute; left: 8%; width: 40%; bottom: 10%;  z-index: 2;
        h2{ 
            font-size: 36px; line-height: 50px; color: #fff; font-family: $f-l; text-transform: uppercase;  
            font-weight: 800; margin-bottom: 10px;
        }
        p{ font-size: 24px; line-height: 34px; color: #fff; font-family: $f-l; font-weight: 300; display: block;}
       
    }
    .form-login{ 
        position: absolute; bottom:5%; right: 8%;
        right:8%; width: 470px; padding: 145px 50px 20px; @include boxSizing(border-box); @include borderRadius(20px);
        background: rgba(17,80,167,1);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(17,80,167,1)), color-stop(100%, rgba(11,60,128,1)));
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(17,80,167,1)), to(rgba(11,60,128,1)));
        background: linear-gradient(to bottom, rgba(17,80,167,1) 0%, rgba(11,60,128,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1150a7', endColorstr='#0b3c80', GradientType=0 );
        p{ font-size:15px; font-family: $f-l; line-height: 25px; color:#fff; text-align: center; margin-top: 20px;   }
         a{
            display: block; color: #fff; font-size: 17px; font-family: $f-l; text-align: center; padding-bottom: 20px;
        }
    }
    .form{
        .row{
            @include afterclear;display: table; width: 100%;
            .left.right{ display: table-cell; vertical-align: middle;  }
            .check{
                position: relative;
                span{color:#86A2C9; font-family: $f-l; font-size: 15px; padding-left: 30px; }
                input[type='checkbox']{ 
                    position: absolute; background: url(../images/material/ic-chck.png)no-repeat left; 
                    width: 100%; left: 0; top: 0; bottom: 0; margin: auto 0; height:20px; z-index: 1;
                    cursor: pointer;
                    &:checked{
                        background:url(../images/material/ic-checked.png)no-repeat left;
                    }
                }
            }
            .right{
                a{ color:#86A2C9; font-family: $f-l; font-size: 15px; display: inline-block; }
            }
        }
        .col{ 
            float: left; width: 100%; margin-bottom: 20px; 
            &.half{
                width: 48%; margin-right: 4%;
                &:last-child{ margin-right:0; }
            }
        }
        label{ display: block; margin-bottom: 10px; font-size: 14px; font-family: $f-m; color: #fff; }
        input[type='text'], 
        input[type='password'], 
        input[type='email'],{ 
            width: 100%; @include borderRadius(60px); height: 45px; border: none;  padding: 0px 30px;
            @include boxSizing(border-box); font-size: 16px; color: #111; font-family: $f-m;
        }
        input[type='submit']{
            width: 100%; @include borderRadius(60px); height: 45px; border: none;  
            background:#088439; font-size: 17px; font-family: $f-m; color: #fff; text-transform: uppercase;
        }
        @include placeholder{ font-size: 16px; font-family: $f-m; color: #555555; }

    }
}
.milestone{
    border-left: 2px solid #2A64B5;
    figure{margin-bottom: 10px; display: block;}
    .list-milestone{
        position: relative;padding-left: 85px; margin-bottom: 40px;
        h5{ font-size: 18px; line-height: 28px; font-weight: bold; color: #333333;   }
        p{ font-size:16px; line-height: 26px; }
        &:before{ 
            content:''; position: absolute; left: -2px; top: 0; background: url(../images/material/ic-ul.png)no-repeat;
            width: 17px; height: 13px; z-index: 2;
        }
    }
}
.visimisi{
   margin: 15px 0;
    .box{ position: relative;  padding-left: 90px; min-height: 80px; margin-bottom: 20px; display: block; }
    figure{
       position: absolute; left: 0; top: 0; width: 60px; height: 60px;
    }
    h5{ font-size: 18px; font-weight: bold; margin-bottom: 15px; }
    p{ font-size:16px; line-height:30px; color: #777777; word-break: break-word; font-weight: 500; }
    ul{
        li{ font-size:16px; line-height: 26px; color: #777777; margin: 0 0 10px;  }
    }
    .tittle-value{ display: block; margin-bottom: 30px; text-transform: uppercase; font-weight: bold; font-size: 18px; }
    &.block{

        figure{ position: relative; vertical-align: middle;display: table-cell;  }
        h5{ display: table-cell;vertical-align: middle; padding-left: 15px;}
        .box{ padding-left: 0; }
        .tittle-box{ display: table; width: 100%; margin-bottom: 20px;}
        .text{
            display: block;
            &.visi{
                p{ font-size:20px; font-weight: bold;  color: #717171; line-height: 30px;  }
            }
        }
    }
}
.twobox-bg{
    .list-box{
        margin-bottom: 40px; width: 100%; display: table; position: relative;
        padding:40px; @include boxSizing(border-box); z-index: 1;
        &:before{
            content:''; height: 75%; width: 100%; background: #F4F5F8; position: absolute; left: 0; bottom: 0; right: 0;
            z-index: -1; @include borderRadius(10px); @include transition(.2s all ease-out);
        }
        &:hover{
            &:before{ background:#093C81; }
            .text{
                h5{ color: #fff; }
                p{ color:#A8C7F7;  }
            }
        }
        &.nohover{
            &:before{ background:#093C81; }
            .text{
                h5{ color: #fff; }
                p{ color:#A8C7F7;  }
            }
        }


    }
    &.popup{
        .list-box{
            figure{
                cursor: pointer;
            }
        }
    }
    &.career-slider{
        .list-box{ margin: 0 40px 40px; }
        .slick-dots{
            position: absolute; right: 0; top: 0; 
            li{ 
                display: inline-block; margin: 0 10px;
                button{ border:none; font-size: 0;  width: 40px; height: 3px; background: #BEBEBE; cursor: pointer; }
                &.slick-active{
                    button{ background: #5BBB4C; }
                }
            }

        }
    }
    &.minpadd{
        .list-box{ padding: 0px 40px 40px 40px; }
    }
    figure,.text{ display: table-cell;  }
    figure{
        @include borderRadius(10px); overflow:hidden;
        img{ width: 100%; }
    }
    .text{ 
        width: 70%; padding: 0px 40px; @include boxSizing(border-box); vertical-align: bottom; 
        @include transition(.2s all ease-out);
        h6{ font-size:15px; color:#3996D9; margin-bottom: 5px; line-height: 19px; font-weight: bold; }
        h5{ font-size: 18px; font-weight: 500; line-height: 24px; margin-bottom: 5px; font-weight: 500; }
        p{ font-size: 15px; line-height: 24px; color: #555555; max-height: 72px; overflow:hidden; }
    }
}
.award{
    @extend .flex-list; @include afterclear; margin: 0 -20px;
    .list-award{
        float: left; width: 33.333%; padding: 0 20px; @include boxSizing(border-box);
        margin-bottom: 30px; cursor: pointer;
        figure{
            @include borderRadius(10px); overflow: hidden; margin-bottom: 20px;
            img{ width: 100%; @include transition(.2s all $ease-in); }
        }
        .text{
            h6{ font-size:15px; color: #164484;  font-weight: bold; margin-bottom: 5px; }
            h5{ font-size: 16px; line-height: 24px; font-weight: 500;  color: #333333; margin-bottom: 5px;}
            p{ font-size: 16px; line-height: 24px; font-weight: 300;  }
        }
        &:hover{
            figure{
                img{ @include transform(scale(1.1)); }
            }
        }
    }
}
.pdf-sect{
    .row-filter{
        margin: 40px 0;
        .left{ width: 320px; position: relative; }
        .right{  }
        input[type='text']{ 
            width: 100%; height: 40px; @include borderRadius(10px); @include boxSizing(border-box);
            padding-right: 15%; border-color: #82B93B;
        }
        input[type='submit']{ 
            width: 24px; height: 24px; position: absolute; right: 5%; top: 0; bottom: 0; font-size: 0; 
            margin: auto 0; background: url(../images/material/ic-search.png)no-repeat; border: none;
            cursor: pointer;
        }
        label{ font-size:12px; color: #585858;  }
        select{ 
            width: 190px; height: 40px; @include borderRadius(10px); @include boxSizing(border-box);  border-color: #82B93B; 
            background: url(../images/material/arr-select.png)no-repeat 90% center; 
            padding-right: 40px;
        }
        @include placeholder{ font-size: 12px; color:#585858;  }
    }
    .wrap-pdf{
        margin-top: 40px;-webkit-flex-flow: row wrap; display: flex;display: -ms-flexbox;display: -webkit-flex;display: -moz-box;
        .row{ @include afterclear; }
        .col{ 
            float: none; width: 48%; margin-right: 4%; display: block; margin-bottom: 30px;
            background: #F2F5F8; @include borderRadius(10px); padding: 15px 40px 35px 25px; position: relative;
            @include boxSizing(border-box); @include transition(.2s all $ease-out); top: 0;
            &:nth-child(2n){ margin-right: 0; }
            figure,.text{ display: table-cell; vertical-align: top; }
            .text {padding-left: 10px;}
            figure{
                width: 25px;padding-right: 10px;
            }
            &:after{
                content:''; position: absolute; right: 5%; top: 0; bottom: 0; margin: auto 0;
                background: url(../images/material/arr-select.png)no-repeat center;
                width: 12px; height: 8px; @include transform(rotate(-90deg)); @include transition(.2s all $ease-out);
            }
            &:hover{
                @include boxShadow(0 2px 15px -11px #000); top: 1px;
                &:after{
                    right:2%;
                }
            }
        }
        h5{ font-size:17px; font-family: $f-l; color: #555555;  margin-bottom: 0px; line-height: 24px; }
        small{ font-size:12px; color: #21549F; font-family: $f-l; font-weight: bold; position: absolute; bottom: 13px; left: 72px; }
    }
}

.twobox-list{
    margin: 40px 0 60px;
    .list{  width: 100%; display: table; margin-bottom:40px;  }
    figure,.text{ display: table-cell; vertical-align: middle; }
    .text{ width: 60%; padding:0 30px; @include boxSizing(border-box);  }
    figure{ @include borderRadius(10px); overflow:hidden; position: relative; }
    h5{ font-size:20px; font-family: $f-l; font-weight: bold; font-size: 20px; margin-bottom: 15px;  line-height: 26px; color: #333;}
    p{ font-size: 16px; color:#333333; line-height: 24px;  }
    .date{ 
        position: absolute; right: 20px; bottom: 0; padding:15px 25px 15px 40px; @include boxSizing(border-box);
        color: #E3FFEE; font-weight: bold; font-size: 16px; background: rgba(103,168,49,.95);
        @include borderRadius(40px 0px 0px 0px);
    }
    .tot-photo{ 
        font-size:16px; font-weight: 500; color: #093C81; font-weight: 500; padding-left:40px;
        position: relative; 
        &:before{ 
            content:''; position: absolute; left: 0; top: 0; width: 30px; height: 27px; 
            background: url(../images/material/ic-gallery.png)no-repeat center;
        }
    }
}

.detail-corpo{
    padding: 0 55px 140px;
    .wrap-slider-detail{ 
        margin-top: -55px; margin-bottom: 40px;
        .slider-big-det{
            img{ width: 100%; @include borderRadius(10px); }
        }
        .slider-small-det{
            margin: -20px auto 0; text-align: center; position: relative; z-index: 2;
            padding: 0px 40px; @include boxSizing(border-box);
            img{ width: 100%; }
            .list{ margin:0 5px; @include borderRadius(10px); overflow:hidden; border: 2px solid transparent;  }
            .slick-arrow{ 
                position: absolute; left: 1%; top: 0; bottom: 0; font-size: 0; border: none; 
                background: url(../images/material/arr-paging.png)no-repeat center; width: 13px; height: 20px;
                margin: auto 0; z-index: 2;  cursor: pointer;
                &.slick-next{
                    left: auto; right: 1%; @include transform(rotate(180deg));
                }
            }
            .slick-current{ border:2px solid #5BBA4C; }
        }
    }
    &.nopadbot{ 
        padding-bottom: 0; padding: 0 55px; 
        .slick-list{overflow: unset;}
    }
}
.product{
  
}
.divider{ 
    margin: 100px 0px 60px; display: block; border: 1px solid #E5E5E5; 
    &.abs{
        position: absolute; left: 0; right: 0;  margin: 40px 0px;  height: 0; width: 100%;
    }
}
.share-arc{
    display: table; margin-bottom: 30px;
    h5{ 
        font-size:16px; font-weight: bold; color: #333;  vertical-align: middle;  display: table-cell; vertical-align: middle;
        padding-right: 20px;
    }
    a{ display: table-cell; vertical-align: middle;  padding:0 5px; }
}
.wrap-bg-blue{
    padding: 50px 40px; @include boxSizing(border-box); background: #1A58AD; @include borderRadius(10px);
    .std-content{
        p{ color: #fff; }
        .linkto{ color: #fff; }
        .bold{ color: #fff; }
    }
    .btn{ 
        border: none; line-height: 40px; cursor: pointer;
    }
}
.related-news{
    @extend .flex-list; margin:100px -15px 0;
    .list-news{ 
        width: 33.333%; padding: 0px 15px; @include boxSizing(border-box);  
        &:hover{
            figure{
                img{ @include transform(scale(1.1)); }
            }
        }
    }
    figure{
        @include borderRadius(10px); overflow:hidden; margin-bottom: 10px;
        img{ width: 100%; @include transition(.2s all $ease-out); }
    }
    .date{ font-size:15px; color:#164484; font-weight: bold; display: block; margin-bottom: 10px;   }
    p{ font-size:18px; font-family: $f-l; font-weight: bold; line-height: 30px; }
}

.result-simulasi{ 
    margin-top: 40px; 
    table{
        width: 100%; @include borderRadius(10px); overflow:hidden;
        thead{
            th{ 
                background:#1150A7; padding: 20px 30px; @include boxSizing(border-box); text-align: left; color: #fff; 
                font-size: 17px; font-family: $f-l;
            }
        }
        tbody{
            background: #E7ECF2;
            td{ 
                padding: 20px 30px; @include boxSizing(border-box); font-size: 16px; color: #777777;
                &:nth-child(2n){ text-align:right; } 
            }
            hr{ border: 1px solid rgba(112,112,112,0.2); }
            .result{
                td{
                    &:first-child{ font-size:17px; font-family: $f-l; color: #555555;  }
                    &:last-child{ font-size:20px; font-weight: bold; font-family: $f-l; color: #555555;  }
                }
            }
            i{ font-style: italic; font-size: 15px; font-family: $f-l; color: #717171; }
        }
    }
    .row-btn{
        display: table; width: 100%; margin-top: 40px;
        .left,.right{ float: none;  display: table-cell; vertical-align: middle; }
        .left{ width: 50%; }
        .right{ text-align: right; }
        .ic{
            display: inline-block; padding-right: 60px; @include boxSizing(border-box); position: relative;
            margin-right: 20px; border-right: 1px solid rgba(112,112,112,0.32); font-size: 16px; font-weight: bold;
            line-height: 26px; color: #333;
            &:after{ 
                content:''; position: absolute; right: 15px; top: 0; bottom: 0; margin: auto 0; 
                background: url(../images/material/link-2.png)no-repeat center; width: 23px; height: 23px;
            }
            &.download{
                border: none; margin-right: 0;
                &:after{
                    background:url(../images/material/ic-download.png)no-repeat center; background-size: cover;
                }
            }
        }
        .right{
            a{ 
                margin-right: 25px; 
                &:last-child{ margin-right:0; }
            }
        }
    }    
}
.feature{
    @include afterclear; margin: 80px 0px 0;
    .list-feat{
        float: left; padding:0px 50px; width: 20%; @include boxSizing(border-box); 
        margin-bottom: 20px;
        figure{ 
            text-align: center; width: 60px;height: 60px; background:#F5F7FA;  margin: 0px auto 10px;
            @include borderRadius(100%); overflow: hidden; position: relative;
            img{ position: absolute; left: 0; top: 0; bottom: 0; right: 0; margin: auto; }
        }
        .text{ text-align: center; }
        h6{ font-size: 14px; font-weight: bold; font-family: $f-l; color: #9B9B9B; }
        h5{ font-size: 16px; font-weight: bold; font-family: $f-l; }
    }
}
.calldealer{
    display: table; width: 100%; background: #E7ECF2; padding: 20px 50px; @include boxSizing(border-box);
    margin-top: 60px;
    @include borderRadius(10px); overflow:hidden;
    h4{ font-size: 20px; margin-bottom: 5px; line-height: 30px; font-weight: bold; }
    p{ font-size: 15px; font-family: $f-l; line-height: 24px; }
    .left,.right{ vertical-align: middle; display: table-cell; float: none; }
    .left{ width: 40%; } 
    .right{ text-align: right; }
}

.milestone-slider{
    position: relative; margin-top: 100px;
    &:before{ 
        content:''; position: absolute; left: 0; top: 53%;right: 0; margin: auto; width: 100%; height: 10px; background: #088439;  
        z-index: -1; @include borderRadius(30px);
    }
    .list{
        padding: 90px 0px 40px;position: relative;text-align: center;  @include boxSizing(border-box);
        figure{
          background: url('../images/material/milestone-border.png')no-repeat center;padding: 32px;@include boxSizing(border-box);display: inline-block;
            overflow: hidden; position: relative; @include borderRadius(100%); cursor: pointer;  @include transition(.2s all $ease-out);
            img{width: 100%;@include borderRadius(100%); @include transition(.2s all $ease-out);}
        }
        &:hover{
            figure{
                img{ @include transform(scale(1.03)); }
            }
        }
        .desc{
            position: absolute;top: 40px;left: 0px;right: 0px;text-align: center;  @include transition(.2s all $ease-out);
            h5{font-weight: 900;font-size: 32px;margin-bottom:0px;}
            small{ font-size:12px; font-weight: bold;  }
            p,ul{text-align: left;margin-bottom: 0px;font-size: 14px;line-height: 20px;}
            ul{
                li{
                    position: relative;padding-left: 30px;
                    &:before{content:"";position: absolute;top: 6px;left: 5px;width: 6px;height: 6px;background: #7892B7;@include borderRadius(100%);}
                }
            }
            a{font-weight: 900;font-size: 14px;color: #093C81;}
            .text{overflow: hidden;}
        }
        &.slick-current{
            figure{ @include transform(scale(1.4)); @include transition(.2s all $ease-out); }
            .desc{ top: 0; }
        }
        
    }
}

.detail-milestone{
    .list{
        padding: 10px 0px;
        .inner{ 
            padding: 30px 40px; @include boxSizing(border-box); background: #F2F2F2; @include borderRadius(10px); position: relative; 
            &:before{ 
                content:'';width: 0; height: 0; border-left: 10px solid transparent; border-right: 10px solid transparent; 
                border-bottom: 10px solid #F2F2F2; position: absolute; top:-10px; left: 0; right: 0; z-index: 2;
                margin: 0 auto;
            }
        }
    }
    p{ font-size:16px; line-height: 24px;color: #777;  }
    ul{
        li{ 
            color: #777; line-height: 26px;  font-weight: 400;
            &:before{ top:6px; }
        }
    }
}
.notes{ display: block; font-style: italic; font-size: 18px; color:#3C3C3C; font-family: $f-l;  }
.page100vh{
    .wrap{
        height: 100vh; position: relative; overflow: hidden;
        &:before{ 
            content:''; position: absolute; bottom: 0; left: 0; right: 0; width: 100%;
            background: url(../images/material/shadow-100vh.png)no-repeat center; height: 508px;
            z-index: 1;
         }
        .text{ 
            position: absolute; top:50%; @include transform(translateY(-40%)); left: 30%; right:30%;
            text-align: center; z-index: 2;
            h2{ font-size: 300px; color: #fff; font-weight: bold; margin-bottom: 40px; }
            h5{ font-size:30px; color:#fff; font-weight: bold; margin-bottom: 40px;  }
            p{ 
                font-size:16px; color: #fff; line-height: 26px;  
                a{ color: #fff; }
            }
            &.under{
                h2{ font-size: 120px; }
            }
        } 
        figure{
            width: 100%; height: 100%;
            img{ width: 100%; height: 100%; object-fit: cover; }
        }   
    }
}


/* footer
----------------------------------------------------------------------------------------------*/
.calculator-foot{ 
    width: 100%; padding: 15px 0px; @include boxSizing(border-box); background: #088439;
    color: #fff; position: fixed; bottom: 0; left: 0; right: 0; z-index: 1;
    &.float{ position: relative; bottom: auto; left: auto; right: auto;  padding: 25px 0px; }
    &.show{ }
    .lf-calculator{
       position: relative;width:100%; padding-right: 20%; @include boxSizing(border-box);
       display: table;
       .btn-simulasi{
            position: relative; display: inline-block; padding-right: 50px; line-height: 40px; vertical-align: middle;
            margin-right: 40px; cursor: pointer; display: table-cell; text-align: left;
           h3{ 
                position: relative; padding-right: 50px; @include boxSizing(border-box);
                display: inline-block;
                 &:after{ 
                    content:''; position: absolute; right: 0; top: 0; bottom: 0; margin:auto 0; 
                    background: url(../images/material/arr-btn.png)no-repeat center;
                    width: 29px; height: 28px; @include transform(rotate(180deg)); @include transition(.3s all ease-out);
                }
            }
            &.active{
                h3{
                    &:after{ @include transform(rotate(0deg)); }
                }
            }
       }
        h3{font-size: 25px; color: #fff; line-height: 40px; vertical-align: middle;}    
        p{ 
            display: inline-block;  margin: 0px; vertical-align: middle;  text-align: right; display: table-cell;
            padding-right: 40px; position: relative; width: 400px;
        }
    }
    .wrapper{ @include afterclear; position: relative;}
    .btn{ 
        position: absolute; right: 0; top: 0; bottom: 0; margin: auto 0; height: 40px;
        line-height: 40px; font-size: 14px; letter-spacing: 1.5px;
    }
}
.search-result{
    .tittle-result{ 
        margin-bottom:60px;  
        h3{ font-size: 30px; color: #093C81; font-weight: bold; }
    }
    .result{ margin-bottom: 60px; display: block;}
    .list{ margin-bottom:60px; display: block;  }
    h5{ margin-bottom: 20px; font-size: 20px; font-weight: bold; color: #3C3C3C; }
    p{ font-size: 16px; line-height: 24px; color: #333333; }
    .bold{ 
        display: inline-block; background: #093C81; padding: 2px 5px; @include borderRadius(5px); color: #fff;  
        font-size: 16px; margin-right: 5px; font-weight: normal;
    }
 }


.list-job {
    -webkit-flex-flow: row wrap; display: flex;display: -ms-flexbox;display: -webkit-flex;display: -moz-box; margin: 0 -10px;
    .item {
        padding: 0 10px; width: 33.333%; @include boxSizing(border-box); margin-bottom: 30px; position: relative;
        a {
            background: #fff; @include borderRadius(10px); height: 100%; position: relative; display: block; padding: 25px  20px 45px;
            @include boxShadow(0 0 10px -2px rgba(0,0,0,0.1)); color: #333; @include boxSizing(border-box); @include transition(all ease-out 0.25s);
        }
        .dte {font-size: 14px; color: #093C81; display: block; font-weight: 600;@include transition(all ease-out 0.25s); position: absolute; bottom: 25px; }
        h3 {font-size: 16px; line-height: 22px; margin-bottom: 10px;@include transition(all ease-out 0.25s);}
        .loc {font-size: 13px; color: #093C81; line-height: 18px;@include transition(all ease-out 0.25s); margin-bottom: 10px; display: block;}
        &:hover {
            a {@include transition(all ease-out 0.25s); background:rgba(9,60,129,0.8); color: #fff;}
            .dte,h3,.loc {color: #fff;@include transition(all ease-out 0.25s);}
        }
    }
}


footer{
    position: relative; z-index: 2;
    &.none{ display: none; }
     .foot-top{
        padding: 55px 0 45px; background: #fff;
        h5{ font-size:15px; line-height: 25px; margin-bottom: 15px; font-family: $f-m; color:#3B3B3B;   }
        .wrapper{ @include afterclear; }
        .foot-top-left{
            width: 70%; float: left;
            a{ float: left; margin: 0px 15px; }
            .box-foot{ @include afterclear; float: left;}
        }
        .form-newsletter{ 
            float: left; position: relative; width: 445px; 
           
            input[type=email]{ 
                height: 55px; width: 100%; @include boxSizing(border-box); background: #F0F0F0; border: 1px solid transparent; 
               padding: 0px 100px 0px 20px; font-size: 15px; font-family: $f-m;
                @include borderRadius(25px); 
               &:focus{
                    border:1px solid #82B93A;
               }
            }
            @include placeholder{ color:#848484;  }
            input[type=submit]{ 
                position: absolute; right: 5px; top: 5px; bottom: 5px; font-size: 15px; font-family: $f-m; 
                @include borderRadius(30px); background:#82B93A; color: #fff; padding: 0px 20px; cursor: pointer;
                @include transition(.2s all ease-out);
                &:hover{
                    background:#6B9830;
                }
            }
        }
        .foot-top-right{ float: right; width: 30%; }
        .foot-download{
            a{ 
                margin-right: 20px; 
                img{ @include transition(.2s all $ease-in-out); }
                &:hover{
                    img{ @include transform(scale(1.05)); opacity: .8; }
                }
            }
        }
     }

     .foot-bottom{
        padding: 25px 0px; @include boxSizing(border-box); background: #4E4E4E; color: #fff;
        .wrapper{ @include afterclear; }
        .foot-bottom-left{ 
            font-size:12px;  float: left;
            a{ color: #fff; }
        }
        .foot-bottom-right{
            width: 60%; float: right; @include afterclear; text-align: right;
            a{ 
                color: #fff; margin: 0 10px; font-size: 12px;display: inline-block;
                &:first-child{ margin-left:0; }
                &:last-child{ margin-right:0; }
            }
        }
     }
}




